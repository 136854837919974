import { Container } from '@mui/material'
import ExtraInfoCustomer from '~/src/components/auth/extra-info/customer/ExtraInfoCustomer'
import useGetExtraInfoCustomerTemplate from '~/src/pages/extra-info/customer/styles/useGetExtraInfoCustomerTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import ContentContainer from '~/src/components/utils/layout/ContentContainer'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const ExtraInfoCustomerPage = () => {
  const pageName: keyof typeof RoutesEnum = 'EXTRA_INFO_CUSTOMER'
  const styles = useGetExtraInfoCustomerTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>

      <Container sx={styles?.getPageSx()}>
        <ContentContainer>
          <ExtraInfoCustomer />
        </ContentContainer>
      </Container>
    </SlideFromRight>
  )
}

export default ExtraInfoCustomerPage

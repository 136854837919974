import StylesExtraInfoGuestServiceInterface
  from '~/src/pages/extra-info/guest/styles/StylesExtraInfoGuestServiceInterface'
import StylesExtraInfoGuestServiceStyleOne
  from '~/src/pages/extra-info/guest/styles/StylesExtraInfoGuestServiceStyleOne'
import StylesExtraInfoGuestServiceStyleTwo
  from '~/src/pages/extra-info/guest/styles/StylesExtraInfoGuestServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesExtraInfoCustomerServiceStyleThree
  from '~/src/pages/extra-info/customer/styles/StylesExtraInfoCustomerServiceStyleThree'

const getExtraInfoCustomerTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesExtraInfoGuestServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesExtraInfoGuestServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesExtraInfoCustomerServiceStyleThree(muiTheme)
    default:
      return new StylesExtraInfoGuestServiceStyleOne(muiTheme)
  }
}

export default getExtraInfoCustomerTemplateService

import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesExtraInfoCustomerServiceInterface
  from '~/src/pages/extra-info/customer/styles/StylesExtraInfoCustomerServiceInterface'

export default class StylesExtraInfoCustomerServiceStyleThree extends TemplateServiceStyleBase implements StylesExtraInfoCustomerServiceInterface {
  public getPageSx(): SxProps {
    return {
      my: 4,
      width: '40%',
      [this.muiTheme.breakpoints.only('xs')]: {
        width: '90%'
      },
      [this.muiTheme.breakpoints.only('sm')]: {
        width: '80%'
      },
      [this.muiTheme.breakpoints.only('md')]: {
        width: '65%'
      }
    }
  }
}

import { Box } from '@mui/material'
import { FormikProps, useFormik } from 'formik'
import ExtraInfoCustomerActions from '~/src/components/auth/extra-info/customer/ExtraInfoCustomerActions'
import ExtraInfoCustomerFields from '~/src/components/auth/extra-info/customer/ExtraInfoCustomerFields'
import { CustomerProfileFormInterface } from '~/src/types/forms/CustomerProfileFormInterface'
import useCustomerProfileForm from '~/src/hooks/forms/customer-profile-form/useCustomerProfileForm'
import { useEffect, useRef } from 'react'
import FormError from '~/src/components/error/FormError'
import useUpdateCustomerExtraInfo from '~/src/hooks/auth/useUpdateCustomerExtraInfo'

const ExtraInfoCustomerForm = () => {
  const { update: updateCustomerExtraInfo, loading, errors } = useUpdateCustomerExtraInfo()
  const { initialValues, validationSchema } = useCustomerProfileForm()
  const disabledFields = useRef<Record<keyof CustomerProfileFormInterface, boolean>>({
    email: true,
    firstName: false,
    lastName: false,
    phone: false
  })
  const formik: FormikProps<CustomerProfileFormInterface> = useFormik<CustomerProfileFormInterface>({
    initialValues,
    validationSchema,
    onSubmit: (values: CustomerProfileFormInterface) => {
      void updateCustomerExtraInfo(values)
    }
  })

  /**
   * Being set after the customer has been fetched
   * Works for hard reload of the app
   */
  useEffect(() => {
    formik.setValues(initialValues)
    disabledFields.current = {
      email: !!initialValues.email,
      firstName: false,
      lastName: false,
      phone: false
    }
  }, [ initialValues ])

  return (
    <>
      {errors && <FormError errors={errors} />}

      <Box
        component='form'
        onSubmit={formik.handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5
        }}
      >
        <ExtraInfoCustomerFields
          form={formik}
          disabledFields={disabledFields.current}
        />

        <ExtraInfoCustomerActions
          form={formik}
          loading={loading}
        />
      </Box>
    </>
  )
}

export default ExtraInfoCustomerForm

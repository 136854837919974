import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import StylesExtraInfoCustomerServiceInterface
  from '~/src/pages/extra-info/customer/styles/StylesExtraInfoCustomerServiceInterface'
import getExtraInfoCustomerTemplateService
  from '~/src/pages/extra-info/customer/styles/GetExtraInfoCustomerTemplateService'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetExtraInfoCustomerTemplate = (): StylesExtraInfoCustomerServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('extraInfoCustomer')

  useLayout('extraInfoCustomer')

  return getExtraInfoCustomerTemplateService(pageStyle, muiTheme)
}

export default useGetExtraInfoCustomerTemplate

import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FormikProps } from 'formik'
import { CustomerProfileFormInterface } from '~/src/types/forms/CustomerProfileFormInterface'

interface Props {
  form: FormikProps<CustomerProfileFormInterface>,
  loading: boolean,
}

const ExtraInfoCustomerActions = ({ form, loading }: Props) => {
  const { t } = useTranslations()

  return (
    <Button
      loading={loading}
      disabled={!form.dirty || !form.isValid}
      variant='contained'
      type='submit'
      sx={{
        gridArea: 'button',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '1em'
      }}
    >
      {t('extra_info.customer.action')}
    </Button>
  )
}

export default ExtraInfoCustomerActions
